<template>
  <div>
    <a-modal
      :destroyOnClose="true"
      :visible="visible"
      :title="isUpdateMode ? '修改词条国际化实体' : '创建词条国际化实体'"
      width="40%"
      centered
      okText="确认"
      cancelText="取消"
      @ok="() => onSubmit()"
      @cancel="() => hideDialog()"
    >
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-form-model-item label="国际化实体编号" prop="entity">
          <a-input :disabled="isUpdateMode" width="100%" v-model="form.entity" placeholder="请输入国际化实体编号">
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="国际化实体名称" prop="name">
          <a-input width="100%" v-model="form.name" placeholder="请输入国际化实体名称"> </a-input>
        </a-form-model-item>
        <a-form-model-item label="国际化实体语言" prop="languageIds">
          <a-select
            v-model="languageIds"
            mode="multiple"
            placeholder="请选择国际化实体语言"
            showSearch
            optionFilterProp="children"
            @select="selectLanguage"
            @deselect="deselectLanguage"
          >
            <a-select-option v-for="language in languages" :key="language.id">{{ language.id }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';

export default {
  name: 'I18nEntityFormModal',
  components: {},
  mounted() {
    this.listLanguages();
  },
  data() {
    return {
      rules: {
        entity: [{ required: true, message: '国际化实体编号 不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '国际化实体名称 不能为空', trigger: 'blur' }],
      },
      visible: false,
      isUpdateMode: false,
      languages: [],
      entityLanguageList: [],
      resultCallBack: {
        onSubmit: Function,
      },
      form: {},
      languageIds: [],
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    hideDialog() {
      this.visible = false;
      this.form = {};
    },
    showCreateDialog(callback) {
      this.isUpdateMode = false;
      this.form = {
        id: undefined,
      };
      this.languageIds = [];
      this.resultCallBack.onSubmit = callback;
      this.listLanguages();
      this.visible = true;
    },
    async showUpdateDialog(pararms, callback) {
      this.isUpdateMode = true;
      this.form = clonedeep(pararms);
      this.resultCallBack.onSubmit = callback;
      await this.listEntityLanguage();
      this.listLanguages();
      this.visible = true;
    },
    onSubmit() {
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          this.resultCallBack.onSubmit(this.form, this.languageIds);
          this.hideDialog();
        }
      });
    },
    async selectLanguage(record) {
      if (this.isUpdateMode) {
        this.$apiManager.entityLanguage
          .createEntityLanguage({
            entityId: this.form.id,
            languageId: record,
          })
          .then(() => {
            this.$message.success('绑定语言成功');
          })
          .catch(() => {
            this.languageIds = this.languageIds.filter((language) => language !== record);
          });
      }
    },
    async deselectLanguage(record) {
      if (this.isUpdateMode) {
        const deselect = this.entityLanguageList.find((entityLanguage) => entityLanguage.languageId === record);
        this.$apiManager.entityLanguage
          .deleteEntityLanguage(deselect.id)
          .then(() => {
            this.$message.success('解绑成功');
          })
          .catch(() => {
            this.languageIds.push(record);
          });
      }
    },
    async listLanguages() {
      const response = await this.$apiManager.language.getLanguages({ unpaged: true });
      this.languages = response.data.elements;
    },
    async listEntityLanguage() {
      const response = await this.$apiManager.entityLanguage.getEntityLanguages({
        entityId: this.form.id,
        unpaged: true,
      });
      if (response.data) {
        this.entityLanguageList = response.data.elements;
        this.languageIds = this.entityLanguageList.map((entityLanguage) => entityLanguage.languageId);
      }
    },
  },
};
</script>
